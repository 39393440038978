//Main Homepage Banner
#main-banner {
  margin-bottom: 50px;
  position: relative;

  .main-slider-arrows{
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 1;

    .container{
      position: relative;

      .slick-arrow{
        width:40px;
        height:67px;
        background: transparent;
        font-size: 16px;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        padding: 0;

        &:before,
        &:after{
          width:32px;
          height:2px;
          background: #fff;
          position:relative;
          display: block;
          content: " ";
        }
        &:before {
          transform: rotate(45deg);
          top: 12px;
        }
        &:after {
          transform: rotate(-45deg);
          top: -11px;
        }

        &.slick-next{
          &:before {
            transform: rotate(-45deg);
          }
          &:after {
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  .slick-slide{
      text-align: center;
  }
}


//Quick Tabs
.quick-tabs{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  margin-bottom: 30px;

  h3{
    font-size: 30px;
    color: $primary__color;
  }
  span{
    color: #6b6b6b;
  }
  .col{
    margin-bottom:10px;
    a{
      &:hover{
        text-decoration: none;
      }
    }
    span{
      color: $primary__color;
      font-size:13px;

      &.title{
        font-size:22px;
        margin-bottom:5px;
        color:#000;
      }
    }
  }

}

//Brand Images

//Homepage latest
#homepage-latest{
  margin-bottom:50px;

  & > h3{
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 13px;
    margin-bottom: 30px;
    font-size: 24px;
    color: #39464f;
    font-weight: 400;
  }

  .slick-slider{
    margin: 0 -11px;
  }
}

//Our Favourites
#homepage-our-favourites{
  border-top:1px solid #e5e5e5;
  padding-top:30px;
  margin-bottom:30px;

  h3{
    font-size:22px;
    margin:0 0 30px;
  }
}

//Intro text

//About us Block
.about-block{
  display: flex;
  flex-direction: row;
  align-items: center;

  @include max-screen(1005px) {
    flex-direction: column;
    align-items: center;
    max-width: 659px;
    margin: 0 auto;
  }


  img{
    width:50%;

    @include max-screen(1005px) {
        width:40%;
    }
    @include max-screen(1005px) {
      width:auto;
    }
  }



  & > div{
    padding:0 100px;

    @include max-screen(1005px) {
      padding:50px;
    }
    h3{
      font-size:38px;
      color:#fff;
    }
    p{
      color:#fff;
      font-size:14px;
    }
    .p-l{
      font-size:20px;
    }
  }
}

//Why Block
.why-block{
  padding: 100px 0;
  margin-bottom: 68px;

  h3{
    font-size: 22px;
    margin: 0 0 50px;
  }
  .columns{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 40px;
    margin: 0;

    @include max-screen(800px) {
      flex-direction: column;
    }


    &:after{
      display: none !important;
    }
    .col {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 20px;

      p{
        color:#9c9c9c;
      }
    }
  }
}
