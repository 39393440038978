//
//  Main Header
//  ---------------------------------------------

#cookie-status{
    display: none;
}
.page-header {

    position: relative;
    z-index: 35;
    background: #fff;

    #header-top{
        background:#b0a89f;

        .container{
            display: flex;
            flex-direction: row;
            justify-content: center;

            @include max-screen($screen__s) {
                display:block;
            }

            p{
                margin: 0;
                color: #fff;
                padding: 10px 0;
            }
        }

    }

    .header.content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 5px;

        @include max-screen($screen__l) {
            text-align: center;
            padding-top: $indent__s;
        }
        @include max-screen($screen__s) {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        //Logo
        .logo {
            margin: 11px 0 0 0;
            text-align: center;
            float: left;

            @include max-screen($screen__m) {
                text-align: left;
                padding-left: 15px;
                width: 150px;
                margin:15px 0 15px;
            }

            img {
                @include max-screen($screen__l) {
                    display: inline-block;
                }
            }

            .page-print & {
                float: none;
            }
        }

        #header-buttons{
            display: inline-flex;
            gap: 20px;
        }
        .header.contact {
            @include lib-font-size(14px);
            float: right;
            margin: 34px $indent__base 0 0;

            a {
                color: $text__color;
                margin-left: $indent__xs;

                .fas {
                    margin-right: $indent__xs;
                }
            }
        }
        .header.wishlist {
            display: inline-flex;
            list-style: none;
            padding:0;
            margin:0;
            width: 45px;

            @include max-screen($screen__m) {
                display: none;
            }

            & > li {
                margin:0;

                & > a {
                    &:before{
                        content: ' ';
                        display: block;
                        width: 45px;
                        height: 40px;
                        margin-bottom: 5px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-image: url(../images/wishlist.png);
                    }
                    display: block;
                    color: #39464f;
                    background-repeat: no-repeat;
                    background-position: center;
                    font-size: 12px;
                }
            }
        }

        .header.account {
            display: inline-flex;
            list-style: none;
            padding:0;
            margin:0;
            width: 45px;

            @include max-screen($screen__m) {
                display: none;
            }

            & > li {
                margin:0;

                .toggle {
                    background-repeat: no-repeat;
                    padding: 0;
                    background-position: center;
                    width: 52px;
                    text-align: center;
                    font-size: 12px;

                    &:before{
                        background-image: url(../images/account.png);
                        width: 45px;
                        height: 40px;
                        content: ' ';
                        margin-bottom: 5px;
                        display: block;
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                    &:after{
                        display: none !important;
                    }

                    span{
                        display:none;
                    }
                }

                .dropdown-menu{
                    width: 405px;
                    left: -300px;

                    &:before {
                        left: auto;
                        right: 86px;
                    }
                    &::after {
                        left: auto;
                        right: 85px;
                    }
                    .action{
                        width: 100%;

                        &.logout,
                        &.login{
                            margin-bottom: 20px;
                        }

                        &.new-customer{
                            width: 100%;
                            display: block;
                            padding: 10px;
                            color: $primary__color;
                        }
                    }
                    .title{
                        color: #919191;
                        text-align: left;
                        margin-bottom: 10px;
                    }
                    ul{
                        list-style: none;
                        padding: 0;

                        li{
                            text-align: left;
                            padding: 0;

                            &:hover{
                                background-color: white;
                            }

                            a{
                                display: block;
                                width: 100%;
                                padding: 4px 0;
                                font-size: 14px;
                                color: $green-dark__color;

                                i{
                                    float: right;
                                }


                            }
                        }
                    }
                }
            }
        }

        .action.track-order {
            margin-left: 43px;
            margin-top: 33px;
            float: left;
            height: 43px;
            line-height: 21px;

            @include max-screen(1190px) {
                display:none;
            }

        }
    }

    #usp{
        background:#f5f5f5;
        border-bottom: 1px solid #f5f5f5;
        border-top: 1px solid #f5f5f5;
        color: $primary__color;
        text-align: center;
        font-size:14px;
        padding:10px 0;

        .container{
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            display: flex;


            & > div{
                flex: 1 1 auto;
                display: flex;
                margin-bottom: 0;
                text-align: center;
                align-items: center;
                align-content: stretch;
                justify-content: center;
                padding: 0 20px;
                color: #464646;
                font-size: 14px;

                @include max-screen(630px) {
                    &:nth-child(2){
                        display:none;
                    }
                }
                @include max-screen(940px) {
                    &:nth-child(3){
                        display:none;
                    }
                }
                @include max-screen(1150px) {
                   &:nth-child(4){
                       display:none;
                   }
                }
                i{
                    color:#538b9c;
                    margin-right: 5px;
                    font-size: 14px;
                }
            }
        }
    }
}

    


