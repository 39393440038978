
.white-background{
  background-color: white;
}
.grey-background{
  background-color:#f5f5f5;
}
.blue-background{
 background-color:#538b9c;
}
.light-grey-background{
  background-color:#fefefe;
}
