//
//  Buttons
//  ---------------------------------------------
$button__disabled__opacity                      : 0.1;

//  Font style
$button__padding                                : 12px 24px;
$button__font-weight                            : 400;
$button__font-family                            : 'Roboto', sans-serif;

$button__border-radius                          : 0px;
$button__border                                 : 0px;
$button__hover__border                          : $button__border;
$button__font-size                              : 14px;
$button__padding                                : 13px 25px;

$button-primary__background                   : $secondary__color;
$button-primary__border                       : 0px;
