//
//  Variables
//  _____________________________________________

$minicart__border-color       : $color-gray80 !default;
$minicart-qty__height         : 24px !default;

//
//  Minicart
//  ---------------------------------------------
.block-giftregistry-shared-items .product-image-wrapper,
.cart.table-wrapper .product-image-wrapper,
.minicart-items .product-image-wrapper {
    height: auto;
    padding: 0 !important;
}
.block-minicart {
    .items-total {
        float: left;
        margin: 0 $indent__s;

        .count {
            font-weight: $font-weight__bold;
        }
    }
    .subtotal {
        @extend .abs-add-clearfix;
        @include lib-font-size(15px);
        margin-bottom: $indent__m;
        margin-top: $indent__s;

        .label {
            float: left;
        }
        .amount {
            float: right;
        }
    }
    .subtitle {
        display: none;

        &.empty {
            display: block;
            font-size: 14px;
            padding: $indent__l 0 $indent__base;
            text-align: center;
        }
    }
    .text {
        &.empty {
            text-align: center;
        }
    }
    .block-content {
        > .actions {
            margin-bottom: $indent__s;

            .primary {
                .view-basket {
                    text-align: center;
                    margin-bottom: $indent__s;
                }
                .checkout {
                    &:before {
                        @extend .fas;
                        @include fa-icon();
                        content: fa-content($fa-var-chevron-right);
                        float: right;
                        @include lib-font-size(16px);
                    }
                }
                div {
                    display: none;
                }
            }
        }
    }
    .block-category-link,
    .block-product-link,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }
}

//
//  Minicart wrapper
//  ---------------------------------------------

header {
    .minicart-wrapper {
        display: flex;
        position: relative;
        width: 45px;

        @include lib-dropdown(
            $_toggle-selector                     : '.action.showcart',
            $_options-selector                    : '.block-minicart'
        );

        .block-minicart {
            padding: $indent__base;
            right: 0;
            z-index: 101;
            box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.41);
            margin-top: 0;
            border-color: #c6c6c6;
            text-align: left;

            @include max-screen($screen__s) {
                width: 290px;
            }
            @include min-screen($screen__s) {
                width: 400px;
            }

            &:before,
            &:after {
                display: none;
            }
            .block-title {
                padding-bottom: $indent__s;
                border-bottom: 1px solid #eeeeee;

                .text {
                    @include lib-font-size(15px);
                    font-weight: normal;
                }
            }

            .action.view-basket{
                color: $green-dark__color;
                text-decoration: underline;
            }
        }
        .product {
            .actions {
                float: left;
            }
        }
        .action {
            &.close {
                @include lib-button-icon(
                    $icon-remove,
                    $_icon-font-size        : 32px,
                    $_icon-font-line-height : 32px,
                    $_icon-font-text-hide   : true
                );
                @include lib-button-reset();
                height: 40px;
                position: absolute;
                right: 0;
                top: 0;
                width: 40px;
            }

            &.showcart {
                white-space: nowrap;
                background-image: url(../images/basket.png);
                text-align: center;
                background-position: center;
                background-repeat: no-repeat;
                content: ' ';
                display: block;
                width: 100%;
                height: 40px;

                @include max-screen($screen__l) {
                    min-width: auto;
                }

                &:after {
                    display: none;
                }
                &.active {
                    &:after {
                        display: none;
                    }
                }
                .text {
                    @extend .abs-visually-hidden;
                }
                .counter {
                    position: relative;
                    padding-right: 5px;
                    position: absolute;

                    &.empty{
                        display: none !important;
                    }
                    .counter-number {
                        font-weight: bold;

                        &:empty {
                            &:before {
                                content: "0";
                            }
                        }
                    }
                    .counter-label {
                        background: #538b9c;
                        display: block;
                        position: absolute;
                        clip: auto;
                        height: 20px;
                        padding: 5px;
                        text-align: center;
                        width: 20px;
                        font-size: 9px;
                        color: white;
                        -webkit-border-radius: 55px;
                        -moz-border-radius: 55px;
                        border-radius: 55px;
                    }
                }
                .subtotal{
                    display: inline-block;
                    border-left: 1px solid #cbcbcb;
                    padding: 0 0 0 10px;
                    line-height: 13px;
                }
                .loader {
                    > img {
                        @include lib-css(max-width, 20px);
                        top: 25px;
                        left: 20px;
                        background-color: $secondary__color;
                    }
                }
            }
        }
        .minicart-widgets {
            margin-top: 15px;
        }
        .minicart-items {
            @include lib-list-reset-styles();

            .product-item {
                padding: $indent__m 0;
                border-top: 1px solid #eeeeee;
                position: relative;

                &:first-child {
                    border: 0;
                }
                > .product {
                    @extend .abs-add-clearfix;
                }
                &:hover {
                    background: none;
                }
            }
            .product-image-wrapper {
                @extend .abs-reset-image-wrapper;
            }
            .price-minicart {
                margin-bottom: $indent__xs;
            }
            .product {
                > .product-item-photo,
                > .product-image-container {
                    float: left;
                }
                .toggle {
                    margin-bottom: $indent__xs;
                    @include lib-font-size(13);
                    cursor: pointer;
                    position: relative;

                    &:after {
                        @extend .fas;
                        @include fa-icon;
                        content: fa-content($fa-var-chevron-down);
                        margin-left: $indent__xs;
                    }
                }
                &.active {
                    .toggle {
                        &:after {
                            content: fa-content($fa-var-chevron-up);
                        }
                    }
                }
                .content {
                    @extend .abs-add-clearfix;
                }
            }
            .product-item-name {
                display: block;
                font-weight: $font-weight__regular;
                @include lib-font-size(15);
                margin: 0 0 $indent__xs;
                padding-right: 15px;

                a {
                    @include lib-css(color, $green-dark__color);
                    font-size: 14px;
                }
            }
            .product.options {
                float: left;

                .toggle {
                    display: none;
                }
                .content {
                    display: block !important;
                }
                .tooltip.toggle {
                   @include lib-icon-font(
                        $icon-down,
                        $_icon-font-size        : 28px,
                        $_icon-font-line-height : 28px,
                        $_icon-font-text-hide   : true,
                        $_icon-font-margin      : -3px 0 0 7px,
                        $_icon-font-position    : after
                    );

                    .details {
                        display: block;
                    }
                }
            }
            .product-item-details {
                padding-left: 80px;
            }
            .product-item-pricing {
                margin-top: $indent__xs;
                margin-bottom: $indent__s;

                .price {
                    @include lib-font-size(15);
                }
            }



            .details-qty {
                float: left;
                position: relative;

                .btn-qty{
                    width: 29px;
                    height: 29px;
                    color: #aaaaaa;
                    padding: 0;
                    border-radius: 2px;
                    vertical-align: bottom;
                }
                .label {
                    display: none;
                }
                .item-qty {
                    @include lib-font-size(11);
                    text-align: center;
                    width: 33px;
                    height: 29px;
                    border-color: #e4e4e4;
                    border-radius: 0;
                    color: #2f2f2f;
                }

                button[title="Update"]{
                    background: transparent;
                    padding: 0;
                    border: 0;
                    position: absolute;
                    bottom: 23px;
                    left: 104px;
                    top: 5px;
                    width: auto;
                }
            }

            .update-cart-item{
                background: transparent;
                padding: 0;
                border: 0;
                position: absolute;
                bottom: -3px;
                width: 96px;
            }
            .price-container{
                float: right;
            }
            .subtitle {
                display: none;
            }
            .product.options.list {
                @include lib-font-size(13);
                margin: 0;
                width: auto;
                display: inline-block;
                dt{
                    display: none;
                }
                dt,
                dd {
                    margin: 1px 0;
                    padding-right: 10px;
                }

                @include min-screen($screen__m) {
                    dt,
                    dd {
                        float: left;
                    }
                    dt {
                        width: 60%;

                        &:after {
                            content: ":";
                        }
                    }
                    dd {
                        width: 40%;
                    }
                }
            }
            .action {
                &.edit {
                    display: none;
                }
                &.delete {
                    color: #da89af;
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-weight: bold;
                }
            }
        }
    }
    .minicart-items-wrapper {
        overflow-x: auto;
        border-bottom: 1px solid #eeeeee;
        margin: 0 -20px;
        padding: 15px;
    }
}
