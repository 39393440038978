//
//  Widgets
//  _____________________________________________

.block-product-link,
.block-category-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}
.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}

.block.widget.block-products-list.grid,
.block.widget.block-new-products.grid {
    padding-bottom: $indent__l;
    margin-bottom: $indent__l;

    @include max-screen($screen__l) {
        margin-bottom: $indent__base;
        padding-bottom: $indent__base;
    }

    .block-title {
        position: relative;
        text-align: center;
        margin-bottom: $indent__l;

        @include max-screen($screen__l) {
            margin-bottom: $indent__base;
        }

        strong {
            position: relative;
            padding: 0 $indent__s;
            background-color: $color-white;
            @include lib-font-size(21px);
            font-weight: normal;
            z-index: 10;
        }
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            border-top: 1px solid $gray-lightest__color;
            z-index: 5;
        }
    }
    .product-items {
        display: none;
    }
}

.block.widget {
    .products-grid {
        .widget-product-grid {
            display: none;
        }
    }
    .ias-noneleft {
        display: none !important;
    }
}

.block-viewed-products-grid {
    .block-title {
        border-bottom: 1px solid $border__color;
        padding-bottom: $indent__m;
        margin-bottom: $indent__l;

        @include max-screen($screen__m) {
            margin-top: $indent__m;
        }

        strong {
            @include lib-font-size(16px);
            font-weight: normal;
        }
    }
}


.widget-product-carousel:not(.slick-initialized){
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 1.5rem;
    display: flex;
    list-style: none;
}