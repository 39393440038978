//
//  Newsletter
//  ---------------------------------------------

.page-footer {
    .newsletter-bar{
        background-color: #f5f5f5;
        padding: 20px 0;
        margin-bottom: 20px;

        @include max-screen($screen__s) {
            text-align: center;
        }


        .block.newsletter{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 26px;
            margin: 0 auto;
            flex-wrap: wrap;

            @include max-screen($screen__s) {
                padding: 0 10px;
                width: 100%;
            }

           .title{
               font-size: 24px;
               margin-bottom:0;
               color: #000000;

               strong{
                   font-weight: 500;
               }
           }
            & > *{
                gap: 26px;
                white-space: nowrap;
                color:#7f7f7f;
                margin-bottom:0;

                @include max-screen($screen__s) {
                    white-space: initial;
                }
            }
            & > p{
                font-size:19px;
            }
            & > div:last-child {
                min-width: 200px;
                flex-grow: 2;
            }

            form.form.subscribe{
                #newsletter{
                    background: #ffffff;
                    border: 1px solid #ffffff;
                    border-radius: 4px;
                    padding-left: 15px;

                    &::placeholder,
                    &::-webkit-input-placeholder,
                    &::-moz-placeholder,
                    &:-ms-input-placeholder,
                    &:-moz-placeholder { /* Firefox 18- */
                        color: white;
                    }
                }
                button.action.subscribe.primary{
                    margin-left: 10px;
                    height: 44px;
                    font-size: 15px;
                    padding: 0 50px;
                    background: $primary__color;
                    border: $primary__color;
                    text-transform: none;
                }
            }
        }
    }
    .block.newsletter {
        .title {
            margin-bottom: 20px;
        }
        .content {
            .form.subscribe {
                display: table;
                width: 100%;

                .field.newsletter {
                    display: table-cell;

                    .label {
                        @extend .abs-visually-hidden;
                    }
                }
                .actions {
                    display: table-cell;
                    width: 1%;
                    vertical-align: top;

                    .action.primary {
                        margin-left: 10px;
                        height: 44px;
                        font-size: 15px;
                        padding: 0 50px;
                        background: $primary__color;
                        border: $primary__color;
                        text-transform: none;
                    }
                }
            }
        }
    }
}
