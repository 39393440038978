//
//  Layout variables
//  _____________________________________________

$layout__max-width                              : 1322px;


$page__background-color                         : #fff;


//
//  colour variables
//  _____________________________________________
@import 'variables/_colors';


//
//  Typography variables
//  _____________________________________________

//  Font families
$font-family-name__base                         : 'Roboto';

//  Colors
$text__color                                    : #000000;
$text__color__muted                             : $primary__color;

//  Indent
$indent__m                                      : 15px;
$indent__xxl                                    : 60px;

//
//  Typography
//  ---------------------------------------------
@import 'variables/_typography';



//
//  Focus
//  ---------------------------------------------

$focus__box-shadow                              : none;

//
//  Breadcrumbs variables
//  _____________________________________________

$breadcrumbs__container-margin                  : 0;
$breadcrumbs__font-size                         : 14px;
$breadcrumbs-icon__use                          : false;
$breadcrumbs-current__color                     : #b0b0b0;
$breadcrumbs-current__font-weight               : 400;

$breadcrumbs-link__color                        : #7c7c7c;
$breadcrumbs-link__visited__color               : $breadcrumbs-link__color;
$breadcrumbs-link__hover__color                 : $breadcrumbs-link__color;
$breadcrumbs-link__active__color                : $breadcrumbs-link__color;

//
//  Tabs variables
//  _____________________________________________

$tab-control__font-size                         : 20px;
$tab-control__font-weight                       : 400;
$tab-control__margin-right                      : 0;
$tab-control__padding-top                       : 15px;
$tab-control__padding-right                     : 30px;
$tab-control__padding-bottom                    : 10px;
$tab-control__padding-left                      : 30px;
$tab-control__border-color                      : $border__color;
$tab-control__border-width                      : 0px;

$tab-control__background-color                  : #ffffff;
$tab-control__color                             : #cacaca;

$tab-control__hover__background-color           : #ffffff;
$tab-control__hover__color                      : $gray__color;

$tab-control__active__background-color          : #ffffff;
$tab-control__active__color                     : $gray__color;

//
//  Tab content
//  ---------------------------------------------

$tab-content__background-color                  : #ffffff;

$tab-content__padding-top                       : 30px;
$tab-content__padding-right                     : 60px;
$tab-content__padding-bottom                    : 30px;
$tab-content__padding-left                      : 60px;

//
//  Accordions
//  ---------------------------------------------

$accordion-control__height                      : auto;
$accordion-control__font-size                   : 20px;
$accordion-control__font-weight                 : 400;
$accordion-control__line-height                 : 1.428571429;
$accordion-control__border-top                  : $tab-control__border-width solid $tab-control__border-color !default;
$accordion-control__border-right                : 0;
$accordion-control__border-bottom               : 0;
$accordion-control__border-left                 : 0;
$accordion-control__margin-bottom               : 0px;
$accordion-control__padding-top                 : 15px;
$accordion-control__padding-right               : 0px;
$accordion-control__padding-bottom              : 10px;
$accordion-control__padding-left                : 0px;

$accordion-control__visited__color              : $gray__color;

$accordion-control__background-color            : #ffffff;
$accordion-control__color                       : #cacaca;

$accordion-control__hover__background-color     : #ffffff;
$accordion-control__hover__color                : $gray__color;

$accordion-control__active__background-color    : #ffffff;
$accordion-control__active__color               : $gray__color;

$accordion-content__background-color            : #ffffff;
$accordion-content__padding                     : 0 0 20px;

//
//  Table variables
//  _____________________________________________

$table-cell__padding-horizontal                 : 30px;
$table-th__color                                : $text__color;

//
//  Navigation variables
//  _____________________________________________

$navigation-level0-item__color                  : $text__color;
$navigation-level0-item__active__border-color   : $primary__color;
$navigation-level0-item__font-weight            : normal;

$navigation-desktop__font-weight                : normal;
$navigation-desktop-level0-item__margin         : 0;
$navigation-desktop-level0-item__padding        : 2px 0 0;
$navigation-desktop-level0-item__line-height    : 19px;
$navigation-desktop-level0-item__color          : $primary__color;
$navigation-desktop-level0-item__hover__color   : $primary__color;
$navigation-desktop-level0-item__hover__border-width            : 4;
$navigation-desktop-level0-item__active__color  : $primary__color;
$navigation-desktop-level0-item__active__border-width           : 4;

$submenu-desktop__font-weight                   : 500;
$submenu-desktop-item__active__border-color     : $primary__color;

//
//  Messages variables
//  _____________________________________________

$message__margin                                : 0 0 20px;

//
//  Links
//  ---------------------------------------------

$link__color                                    : $primary__color;
$link__hover__color                             : $primary__color;

//
//  Button variables
//  _____________________________________________
@import 'variables/_buttons';

//
//  Form variables
//  _____________________________________________

@import 'variables/_forms';


//
//  Fieldset
//  ---------------------------------------------

$form-fieldset-legend__margin                   : false;
$form-fieldset__margin                          : 0 0 15px;

//
//  Select
//  ---------------------------------------------

$select__padding                                : 10px 0 10px 10px;

//
//  Field
//  ---------------------------------------------

//  Form field label
$form-field-label__font-size                    : 14px;
$form-field-label__font-weight                  : 700;
$form-field-type-label-inline__padding          : 8px 0;
$form-field-type-label-inline__align            : left;
$form-field-label-asterisk__color               : #ffffff;
$form-field-label-asterisk__margin              : 0;

//  Form field labeldata
$form-field-type-label-inline__width            : 100%;

//  Form field control
$form-field-type-control-inline__width          : 100%;

//
//  Header
//  _____________________________________________

$header-icons-color                             : $primary__color;
$header-icons-color-hover                       : $secondary__color;

//
//  Footer
//  _____________________________________________

$footer-text__color                             : #94acc6;
$footer-background-dark__color                  : #112e42;
$footer-background-light__color                 : #163f69;

//
//  Rating variables
//  _____________________________________________

$rating-icon__active__color                     : $primary__color;

//
//  Other variables
//  _____________________________________________

$checkout-shipping-item__active__border-color   : $secondary__color;
$account-nav-current-border-color               : $secondary__color;

//
//  Modals
//  _____________________________________________

$modal-popup__padding                           : 3rem;

//
//  Pager variables
//  _____________________________________________

$pager-current__color                           : $text__color;
$pager-item__margin                             : 0 5px 0 0;
$pager__font-size                               : 14px;

//
//  Dropdown variables
//  _____________________________________________
@import 'variables/_dropdown';