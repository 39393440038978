.product-product-builder{
  #builder-header{
    padding: 20px 40px;
    background: whitesmoke;
  }

  .row.primary .product-info-main{
    width:100%;

    #product-info-main-container{
      background:white;
      padding: 0;


      #product-info-body{
        padding:0px;
      }
    }
  }

  #product_addtocart_form{

    .title{
      border-bottom: 1px solid #ddd;
      background: whitesmoke;
      .builder-option{
        position:absolute;
        left:-999999px;
        opacity: 0;
        height:0;
        width:0;
      }
      .mage-error{
        background: #d97b7b;
        text-align: center;
        color: white;
        padding: 10px;
      }
      &.active{
        h4{
          &:before {
            //content: fa-content($fa-var-minus);
          }
        }
      }
      h4{
        margin: 0;
        padding: 15px;
        font-size: 16px;
        font-weight: 500;
        cursor:pointer;
        position:relative;

        &:before {
          @include fa-icon();
          @include far();
          content: fa-content($fa-var-plus);
          color: #c4c9cd;
          position: absolute;
          right: 29px;
          top: 18px;
          font-weight: 900;
          font-size: 20px;
        }
      }
    }

    .content{
      margin: 0 -14px 0 -10px;

      &:after{
        content: " ";
        display: block;
        clear: both;
      }
    }
    //swatches
    .builder-options{
      width:33%;
      float:left;
      text-align: center;
      padding:10px;
      cursor: pointer;

      .option{
        display: inline-block;
        padding:5px;
        background: whitesmoke;

        &.active{
          background: #b0a89f;
          color:white;
        }
      }
    }

  }

  .product-add-form{
    position: relative;
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;

    .box-tocart{
        padding: 0;
        background: whitesmoke;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index:1;
        position: fixed;
      -webkit-box-shadow: 0px 1px 11px 0px rgba(0,0,0,0.51);
      box-shadow: 0px 1px 11px 0px rgba(0,0,0,0.51);
      
        .actions{
          position: relative;
          display: flex;
          flex-grow: 1;
          gap: 0;
          max-width: initial;
          width: 100%;
          justify-content: flex-end;

          .action.tocart{
            font-size: 16px;
            flex: 1 1 100%;
            max-width: 300px;
            font-weight: 600;
          }
      }
    }
    .product-info-price{
      background: white;
      padding: 10px 20px;
      white-space: nowrap;
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;

      //Normal price
      .price-final_price{
        .price-label,
        .price{
          font-size: 20px;
        }
        .price-label{
          font-weight:400;

          &:after{
            content:':';
            display:inline-block;
            margin-right:10px;
          }
        }
        .price{
          font-weight: bold;
        }
      }
    }
  }

  .builder_related_items{
    display: flex;
    align-content: center;
    align-items: center;

    .icon{
      a{
        font-size: 20px;
        padding: 0 30px;
        color:black;

        &.active{
          color:#538b9c;

         i{
           &:before{
            content:'\f00c';
           }
         }
        }
        i{
          font-size: 14px;
          position: relative;
          bottom: 1px;
        }
      }
    }
  }

  /* Related products */
  .block.related .block-content .field.choice.related,
  .block.upsell .block-content .field.choice.related{
    display:block;
  }

  .block.related{
    .products .product-items .product-item{
      border-bottom:0px;
    }
  }
}

@include max-screen($screen__l) {


  .product-product-builder{
    .product-add-form {
      .product-info-price .price-final_price .price-label,
      .product-info-price .price-final_price .price {
        font-size: 16px;
      }
      .box-tocart .actions .action.tocart {
        max-width: 200px;
      }
      .builder_related_items .icon a {
        font-size: 16px;
        padding: 0 20px;
        color: black;
      }
    }
  }

}
@include max-screen($screen__m) {

   .product-product-builder{
     .product-add-form {
       .product-info-price .price-final_price .price-label,
       .product-info-price .price-final_price .price {
         font-size: 16px;
       }



       .box-tocart .actions{
         display:block;

         .product-info-price,
         .action.primary.tocart{
           width:50%;
           float:left;
           max-width: 100%;
         }
       }
       .builder_related_items {
         display: flex;
         align-content: center;
         align-items: center;
         justify-content: space-evenly;
         padding: 10px;

         .icon a {
           font-size: 16px;
           padding: 0 20px;
           color: black;
         }
       }
     }
     .product-info-main .product-info-price .price-box {
       justify-content: center;
     }

   }

 }