//
//  Product tabs
//  ---------------------------------------------

.product.data.items {
    @include min-screen($screen__m) {
        @include lib-data-tabs();
    }
    @include max-screen($screen__m) {
        @include lib-data-accordion();
    }

    & > .item.title {
        @include min-screen($screen__m) {
            margin-left: $indent__l;
        }
        @include max-screen($screen__m) {
            text-align: center;
            margin-bottom: $indent__m;
        }

        & > .switch {
            position: relative;
            box-sizing: content-box;
            text-transform: uppercase;
            letter-spacing: 0.04em;
        }
        &.active {
            & > .switch {
                &:after {
                    content: "";
                    width: 54px;
                    height: 4px;
                    background-color: #819955;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -27px;
                }
            }
        }
    }
}
