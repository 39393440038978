#landing-page-banner{
  position: relative;
  overflow: hidden;
  background: #d8d8da;
  margin-bottom: 20px;
  z-index: -1;

  .container{
    position:relative;
  }
  .landing-text{
    max-width: 750px;
    margin: 0 auto;
    float: none;
    text-align: center;
    background: #d8d8da91;
    padding: 50px 40px;
    position: relative;
    z-index: 1;
  }

  #landing-main-banner{
    position:absolute;
    bottom:0px;
    right:0px;
  }
  .left,.right{
    position: absolute;
    z-index: 0;
    top: 0;
  }
  .left{ left: 0; }
  .right{ right: 0; }

  @include max-screen($screen__m) {
    .right,.left{ display: none }
  }
}

#subcategory-images{
  .subcategory{
    a{
      background: #f5f4f4;
      display: block;
      text-align: center;
      padding: 20px 20px 44px 20px;
      margin-bottom: 20px;

      h3{
        margin-bottom: 18px;
      }
    }
  }
}


.sidebar-navigation{
  ul{
    margin: 0;
    padding: 0;
  }
  .all-category{
    display: none;
  }
  & > ul{
    margin:0;

    & > li{
      display:none;

      &.active{
        display:block;

        & > a{
          display:none;
        }

        ul{
          display:block !important;
        }
      }
    }
  }
}


