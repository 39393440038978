$heading__color__base                           : $black__color;
$heading__font-weight__base                     : bold;
$heading__margin-top__base                      : 10px;

$font-weight__bold                              : bold;
$font-family-name__base                         : 'Roboto', sans-serif;

//H1
$h1__font-size                                  : 30px;
$h1__line-height                                : 33px;
$h1__font-color                                 : $black__color;
$h1__font-weight                                : 400;

$heading__font-family__base                      : 'Roboto', sans-serif;

//H2
$h2__margin-top                                  : $heading__margin-top__base;
$h2__font-family                                 : $heading__font-family__base !default;
//H3
$h3__margin-top                                  : $heading__margin-top__base;
$h3__font-family                                 : 'Roboto', sans-serif;


//h4
$h4__font-family                                 : 'Roboto', sans-serif;

//h5
$h5__font-family                                 : 'Roboto', sans-serif;

//h6
$h6__font-family                                 : 'Roboto', sans-serif;