//
//  Filters
//  ---------------------------------------------

.block.filter {
    margin-bottom: $indent__xl;

    @include max-screen($screen__m) {
        margin-bottom: $indent__base;
    }

    .block-title {
        @include lib-font-size(14);
        color: #000;
        margin: $indent__s 0 $indent__base;

        @include max-screen($screen__l) {
            text-align: center;
            border: 2px solid $secondary__color;
            padding: $indent__s 0;
        }

        strong{
            font-weight: 400;
        }
    }

    .field.search{
        display: none;
    }
    @include max-screen($screen__l) {
        .block-content {
            display: none;
        }
    }

    .block-subtitle {
        display: none;
    }

}

.filter-current {
    .item {
        margin-bottom: $indent__s;
    }

    .filter-label {
        display: block;
        font-weight: $font-weight__bold;

        &:after {
            content: ':';
        }
    }

    .action.remove{
        color: #be2020;
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }

    .item.Yellow,
    .item.Black,
    .item.Red,
    .item.Yellow,
    .item.Green,
    .item.White,
    .item.Cream,
    .item.Orange,
    .item.Purple,
    .item.Blue,
    .item.Brown,
    .item.Pink{
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        border: 1px solid #d7d7d7;
        width: 30px;
        padding: 3px;
        float: left;
        margin-right: 10px;
        height: 30px;
        margin-bottom: 10px;
        transition: background-color 0.5s ease;

        & > a{
            padding: 0;
            text-indent: -999px;
            overflow: hidden;
            height: 22px;
            display: block;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
            background-image: none;
            border: none !important;

            &:before{
                display:none !important;
            }
        }
    }
    .item.Yellow a{background-color: #ffffff;}
    .item.Black a{background-color: #000;}
    .item.Red a{background-color: #e52929;}
    .item.Yellow a{background-color: #e8ea29;}
    .item.Green a{background-color: #7ab441;}
    .item.White a{background-color: #ffffff;}
    .item.Cream a{background-color: #e6e1b3;}
    .item.Orange a{background-color: #ea9818;}
    .item.Purple a{background-color: #9749c9;}
    .item.Blue a{background-color: #428fd7;}
    .item.Pink a{background-color: #df5997;}
    .item.Brown a{background-color: #8b4d31;}

    .item.Yellow:hover{background-color: #ffffff;}
    .item.Black:hover{background-color: #000;}
    .item.Red:hover{background-color: #e52929;}
    .item.Yellow:hover{background-color: #e8ea29;}
    .item.Green:hover{background-color: #7ab441;}
    .item.White:hover{background-color: #ffffff;}
    .item.Cream:hover{background-color: #e6e1b3;}
    .item.Orange:hover{background-color: #ea9818;}
    .item.Purple:hover{background-color: #9749c9;}
    .item.Blue:hover{background-color: #428fd7;}
    .item.Pink:hover{background-color: #df5997;}
    .item.Brown:hover{background-color: #8b4d31;}
}


.filter-options {
    margin: 0;

    &-title {
        @include lib-font-size(14);
        position: relative;
        cursor: pointer;
        padding-top: 8px;
        color: #6d6d6d;
        font-weight: 400;
        margin-bottom: 10px;
        border-top: 1px solid #eaeaea;

        &:after, &:before{
            content: ' ';
            position:absolute;
            background:#39464f;
        }
        &:before {
            width: 1px;
            height: 14px;
            right: 6px;
            top: 10px;
        }
        &:after {
            width: 14px;
            height: 1px;
            right: 0;
            top: 16px;
        }
    }
    &-content {
        @include max-screen($screen__l) {
            display: none;
        }

        .items {
            padding-bottom: $indent__s;
        }
        .item {
            position: relative;
            margin-bottom: $indent__s;
            @include lib-font-size(15);

            a {
                color: $text__color;
                position: relative;

                .count {
                    font-weight: normal;
                }
                &:hover {
                    text-decoration: none;
                }
                /*&:before {
                    width:18px;
                    height:18px;
                    display: block;
                    content: ' ';
                    border: 1px solid #d0d0d0;
                    position:absolute;
                    left: 0;
                    top: 0;
                } */
            }
        }
    }
    &-item {
        &.active {
            .filter-options-title {
                &:before {
                    display: none;
                }
            }
        }
    }
    .count {
        display:none !important;
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}
